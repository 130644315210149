import React, { useState, useEffect } from 'react';
import Day from './Day';
import axios from 'axios';

export default () => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const rawDate = date.toISOString().substring(0, 10);
    axios.get(`posts/${rawDate}`)
      .then(result => {
        setData(data => data.concat({day: date, posts: result.data}));
      });
  }, [date]);

  const triggerPreviousDay = function() {
    let newDate = new Date(date);
    newDate.setDate(date.getDate() - 1);
    setDate(newDate);
  };

  return <>
    <h1 className="title">Posts</h1>
    { data.map(({day, posts}) => <Day key={day} day={day} posts={posts}></Day>) }
    <button onClick={triggerPreviousDay}>Load more</button>
  </>;
};
