import React from 'react';
import Posts from './Posts';
import logo from './logo.png';
import 'bulma';

export default () => {
  const logoStyle = {
    backgroundImage: `url(${logo})`,
    backgroundSize: '28px',
    backgroundPosition: '12px center',
    backgroundRepeat: 'no-repeat',
    paddingLeft: '40px'
  };

  return <>
    <nav className="navbar is-primary">
      <div className="container">
        <div className="navbar-brand">
          <a href="/" className="navbar-item logo" style={logoStyle}>Lezer</a>
        </div>
      </div>
    </nav>

    <div className="section">
      <div className="container">
        <Posts></Posts>
      </div>
    </div>
  </>;
};
