import React from 'react';
import Post from './Post';

export default ({day, posts}) => {
  const openAll = function() {
    const urls = postsToUrls(posts);
    const orderedUrls = isFirefox() ? urls.reverse() : urls;
    orderedUrls.forEach(url => window.open(url));
  };

  return (
    <div className="day" style={{ marginBottom: "20px" }}>
      <h2 className="subtitle">{day.toISOString().substring(0, 10)}</h2>
      <small style={{display: "block", marginTop: "-1.5rem"}}><button onClick={openAll}>open all</button></small>
      {posts.map(post => <Post key={post.id} post={post}></Post>)}
    </div>
  );
};

function postsToUrls(posts) {
  let urls = [];
  posts.forEach(post => {
    urls.push(post.url);
    if (post.comment_url && post.comment_url !== post.url) {
      urls.push(post.comment_url);
    }
  });
  return urls;
}

function isFirefox() {
  return navigator.userAgent.includes("Firefox");
}
