import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRssSquare } from '@fortawesome/free-solid-svg-icons';
import { faRedditSquare, faHackerNewsSquare } from '@fortawesome/free-brands-svg-icons';
import "./Post.scss";

const icons = {
  blog: {
    icon: faRssSquare,
    color: "#007bb6",
  },
  hackernews: {
    icon: faHackerNewsSquare,
    color: "#ff6600",
  },
  reddit: {
    icon: faRedditSquare,
    color: "#ff4500",
  }
};

export default ({post}) => {
  const {icon, color} = icons[post.type];

  return (
    <div className="post">
      <div className="myicon">
        <FontAwesomeIcon icon={icon} size="2x" color={color} />
      </div>
      <div className="link">
        <a href={post.url}>{post.title}</a>
        <small>
          {post.type !== 'blog' &&
            <>
              <a href={post.comment_url}>
                score: {post.score}
                {" - "}
                comments: {post.comments}
              </a>
              {" - "}
            </>
          }
          {post.domain}
        </small>
      </div>
    </div>
  );
};
