import React, { useState } from 'react';

export default ({callback, error}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const submit = (event) => {
    event.preventDefault();
    callback(email, password);
  }

  return <form onSubmit={submit}>
    <input
      value={email}
      onChange={event => setEmail(event.target.value)}
      type="text"
      placeholder="Email Address"
    />
    <input
      value={password}
      onChange={event => setPassword(event.target.value)}
      type="password"
      placeholder="Password"
    />
    <button type="submit">
      Sign Up
    </button>

    { error && <p>{error}</p> }
  </form>;
};
