import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LoginForm from './LoginForm';

const LoginPath = 'login';

export default (Component) => (props) => {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setLoggedIn(true);
    }
    setLoading(false);
  }, []);

  const authenticate = (email, password) => {
    axios.post(LoginPath, { email, password })
      .then(response => {
        localStorage.setItem('authToken', response.data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        setLoggedIn(true);
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          setError('Invalid credentials');
        } else {
          throw error;
        }
      });
  }

  if (loading) {
    return null;
  }

  if (loggedIn) {
    return <Component { ...props } />;
  }

  return <LoginForm callback={authenticate} error={error} />;
};
